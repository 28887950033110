import React from 'react';
import {Button, Stack, Typography, Box} from '@mui/material';
import Modal from '@mui/material/Modal';
import {observer} from 'mobx-react-lite';

import '../../styles/main.css';
import {useTranslation} from 'react-i18next';

interface IDeleteCodeModal {
  handleDeleteCode: (codeId: string, me: string | null) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  codeId: string;
  me: string | undefined;
}

const DeleteCodeModal: React.FC<IDeleteCodeModal> = observer(
  ({handleDeleteCode, isOpen, onClose, codeId, me}) => {
    const {t} = useTranslation();

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.default',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const deleteCodeHandler = () => {
      onClose();
      handleDeleteCode(codeId, me ?? null);
    };

    return (
      <>
        <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            width={{xs: '70%', sm: 'auto'}}
            justifyContent="center"
            alignItems={'center'}
            display="flex"
            flexDirection={'column'}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {`${t('lockDevices:are_you_sure_delete_code')}`}
            </Typography>

            <Stack flexDirection="row" gap={1} justifyContent="flex-end" marginTop={3}>
              <Button variant="contained" color="error" onClick={deleteCodeHandler}>
                {t('lockDevices:delete_code')}
              </Button>
              <Button variant="contained" color="primary" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  },
);

export default DeleteCodeModal;
