import request from 'superagent';
import Config from '../config';
import Common from './common';

// CHECKED INTERFACE
export interface ILockDeviceResult {
  name: string;
  device_id: string;
  room_number: string;
  locked: boolean;
  online: boolean;
  battery_level: number;
  me: string | null;
}

// CHECKED tYPE
export type TGetLockDevicesResponse = Array<ILockDeviceResult>;

// CHECKED INTERFACE
interface IDeviceAccessCode {
  code: string;
  access_code_id: string;
  name: string | null;
  status: string; // TODO DEFINED POSSIBLE STATUSES set setting
}

// CHECKED tYPE
export type TGetDeviceAccessCodesResponse = Array<IDeviceAccessCode>; //  or [] array

export type ICreateCodeResponse = IDeviceAccessCode;

export interface IDeleteAccessCode {
  status: string; // TODO CHECK WHAT OTHER POSSIBLE STATUS ARE BESIDES SUCCESS
}

export default {
  getDevices: function (token: string, hotelId: number, nextPreviousUrl: string | null) {
    const url = nextPreviousUrl || `${Config.API_URL}/lock/devices/${hotelId}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  getDeviceAccessCodes: function (token: string, deviceId: string, me: string | null = null) {
    const url = me
      ? `${Config.API_URL}/lock/device/codes/${deviceId}/${me}/`
      : `${Config.API_URL}/lock/device/codes/${deviceId}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  createCode: function (token: string, deviceId: string, me: string | null, codeName: string) {
    const url = me
      ? `${Config.API_URL}/lock/device/codes/${deviceId}/${me}/`
      : `${Config.API_URL}/lock/device/codes/${deviceId}/`;

    const payload = JSON.stringify({
      name: codeName,
    });

    return request
      .post(url)
      .type(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },
  deleteAccessCode: function (token: string, accessCodeId: string, me: string | null) {
    const url = me
      ? `${Config.API_URL}/lock/device/codes/${accessCodeId}/${me}/`
      : `${Config.API_URL}/lock/device/codes/${accessCodeId}/`;

    return request
      .delete(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send();
  },
};
